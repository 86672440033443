export default class NemesisAdminMenu {
   fire() {
      let tokens  = window.location.href.split('/');
      let link    = tokens[tokens.length - 1];
      let element = $('#' + link + '_link');

      if (!element) {
         return;
      }

      $('li.nav-item a').removeClass('active');
      element.addClass('active');
   }
}
