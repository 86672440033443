if (typeof(Bluefish) === 'undefined') {
   Bluefish = {};
}

Bluefish.Dispatcher = function(selector) {
   this.selector    = selector || 'sjs';
   this.collected   = [];
   this.id          = Date.now();
   this.turbolinked = false;

   this.clear = function() {
      this.collected = [];
   };

   this.data = function() {
      return this.collected;
   };

   this.dispatch = function() {
      this.turbolinked = true;

      var elements = document.getElementsByClassName(this.selector);
      var element, cls, meth;
      this.clear();

      for (var i = 0, len = elements.length; i < len; i++) {
         element = elements[i];
         this.execute(element, this.getContext(element));
      }
   };

   this.getContext = function(element) {
      var tokens  = element.getAttribute('data-cls').split('.');
      var context = window;

      for (var i = 0, len = tokens.length; i < len; i++) {
         context = context[tokens[i]];
      }
      return context;
   };

   this.execute = function(element, context) {
      var rtn = null;

      if (!context) {
         return rtn;
      }

      try {
         var meth = element.getAttribute('data-method') || 'fire';
         var obj  = new (context)();
         var rtn  = obj[meth]();
         this.collected.push({ class_name: obj, method: meth, return_value: rtn });
      } catch (e) {
         this.error(e, null, meth, context);
      }
      return rtn;
   };

   this.error = function(exc, tokens, meth, context) {
      console.error('Dispatcher error:' + exc);
      console.error('Tokens:');
      console.error(tokens);
      console.error('Method:');
      console.error(meth);
      console.error('Context:');
      console.error(context);
   };

   this.turbolinks = function() {
      var ref = this;

      if (this.turbolinked) {
         this.turbolinked = false;
         return;
      }

      document.addEventListener('turbolinks:load', function() {
         ref.dispatch();
      });
   };
};
