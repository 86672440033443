if (typeof(Tobias) === 'undefined') {
   Tobias = {};
}

Tobias.Chat = function() {
   this.concat = false;

   this.attach = function() {
      let ref = this;

      ref.start();

      $('#tobias-input').keypress(function(evt){
         let keycode = (evt.keyCode ? evt.keyCode : evt.which);

         if (keycode == '13') {
            evt.preventDefault();
            ref.send($('#tobias-input').val());
         }
      });

      $('#tobias-send').click(function(evt) {
         evt.preventDefault();
         ref.send($('#tobias-input').val());
      });

      $('#tobias-reset').click(function(evt) {
         evt.preventDefault();

         if (!confirm('Tem certeza?')) {
            return;
         }

         ref.reset();
         ref.start();
      });
   };

   this.print = function(data) {
      let li       = null;
      let messages = data.messages;
      let type     = data.type;
      let div      = $('#tobias-chat');
      let ref      = this;
      let text     = null;

      if (this.concat) {
         li   = $('<li/>');
         text = '';
      }

      messages.forEach(function(message) {
         if (message && message.length > 0) {
            if (!ref.concat) {
               li = $('<li/>');
               li.addClass('tobias-' + type + '-message');
               li.append(message);
               $('ul#tobias-messages').append(li);
            } else {
               li.addClass('tobias-' + type + '-message');
               text = text + message + '<br/>';
            }
         }
      });

      if (this.concat) {
         li.append(text);
         $('ul#tobias-messages').append(li);
      }

      let options = data.options;
      let cls;

      if (options && options.length > 0) {
         options.forEach(function(option) {
            option = String(option);
            cls    = option && option.length > 0 ? 'tobias-option-message' : 'tobias-option-message-empty';
            li     = $('<li/>');
            li.addClass(cls);
            li.append(option);
            $('ul#tobias-messages').append(li);

            li.click(function(evt) {
               evt.preventDefault();
               ref.send($(evt.target).text());
            });
         });
      }

      div.animate({
         scrollTop: 10000
      }, 100);
   };

   this.start = function() {
      this.ajax('get');
   };

   this.send = function(text) {
      this.ajax('patch', text);
   };

   this.reset = function() {
      $('#tobias-messages').empty();
      this.ajax('delete');
   };

   this.setInputType = function(type) {
      $('#tobias-input').attr('type', type);
   };

   this.ajax = function(method, message) {
      let ref  = this;
      let data = {};

      if (message) {
         data.message = message;
         if ($('#tobias-input').attr('type') == 'password') {
            message = '******';
         }
         ref.print({ messages: [message], type: 'outgoing'});

         $('#tobias-input').val('');
      }

      $.ajax({
         url: '/tobias',
         method: method,
         data: data
      }).done(function(data) {
         ref.print(data);
         ref.setInputType(data.input);
      }).fail(function(data) {
         ref.print({ messages: ['Ocorreu um erro, por favor tente novamente.'], type: 'error'});
      });
   };
};
