export default class ValueSelector {
   fire() {
      let ref = this;

      $('.value_selector input[type=button]').click(function(evt) {
         ref.add(evt.target);
      });

      $('.value_selector input[type=radio]').click(function(evt) {
         ref.setType($(evt.target));
         ref.setPattern($(evt.target));
         ref.setStep($(evt.target));
      });

      $('.values-selected button').click(function(evt) {
         evt.preventDefault();
         ref.remove($(evt.target));
      });
   }

   remove(element) {
      if (!confirm('Tem certeza?')) {
         return false;
      }

      $(element).closest('li').remove();
      return true;
   }

   setPattern(element) {
      let type = $(element).val();
      let hash = {
         'text': null,
         'integer': null,
         'float': null,
         'null': '^$',
         'boolean': 'true|false'
      }

      let text = $(element).siblings('input.value-text');
      text.val('');
      text.attr('pattern', hash[type]);
   }

   setStep(element) {
      let type = $(element).val();
      let hash = {
         'text': null,
         'integer': null,
         'float': 0.1,
         'null': null,
         'boolean': null
      }

      let text = $(element).siblings('input.value-text');
      text.val('');
      text.attr('step', hash[type]);
   }

   setType(element) {
      let type = $(element).val();
      let hash = {
         'text': 'text',
         'integer': 'number',
         'float': 'number',
         'null': 'text',
         'boolean': 'text'
      }

      let text = $(element).siblings('input.value-text');
      text.val('');
      text.attr('type', hash[type]);
   }

   checkVal(type, text) {
      if (type == 'text') {
         return true;
      }

      let hash = {
         'integer': /^[0-9]+$/,
         'float': /^[0-9]+\.[0-9]+$/,
         'null': /^$/,
         'boolean': /true|false/i
      };
      return text.match(hash[type]);
   }

   add(element) {
      let model  = $(element).attr('data-model');
      let sing   = $(element).attr('data-singular');
      let plur   = $(element).attr('data-plural');
      let div    = $(element).closest('div');
      let type   = $(div).find('input[type=radio]:checked').val();
      let text   = $(div).find('input.value-text').val();
      let ul     = $('ul#' + model + '_' + plur);
      let li     = $('<li/>');
      let span   = $('<span/>');
      let ref    = this;
      let id     = $(div).find('input[type=radio]:checked').attr('id');

      if (!this.checkVal(type, text)) {
         alert('Por favor utilize o valor correto para o tipo.');
         return false;
      }

      span.append(text.length < 1 ? 'false' : text);
      li.append(span);

      let values = $('<input/>');
      values.attr('type', 'hidden');
      values.attr('name', model + '[' + sing + '_values][]');
      values.val(text);
      li.append(values);

      let types = $('<input/>');
      types.attr('type', 'hidden');
      types.attr('name', model + '[' + sing + '_types][]');
      types.val(type);
      li.append(types);

      let button = $('<button/>');
      button.append('Remover');
      button.addClass('btn btn-danger');
      button.click(function(evt) {
         evt.preventDefault();
         ref.remove($(evt.target));
      });

      li.addClass('list-group-item');
      li.append(button);
      ul.append(li);
   }
}
